import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import '@src/scss/styles.scss';

import Icons from '@src/includes/icons';

import Navigation from '@components/Navigation';
import Footer from '@components/Footer';
import SEO from '../components/SEO';
import useSiteMetadata from '../hooks/useSiteMetadata';

const Layout = ({
    children, mainClassName, invertNav, metaData = { }
}) => {
    const {
        title,
        description,
        siteName,
        twitterHandle,
        siteURL,
        currentUrl
    } = useSiteMetadata();

    return (
        <Fragment>
            <Helmet>
                <meta charset="utf-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <link rel="dns-prefetch" href="//fonts.googleapis.com" />
                <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&family=Roboto+Slab:wght@200;400;700&display=swap" rel="stylesheet" />

                {/*
                We have this in the checkout form file too but the opposite, once the worldpay file has loaded we want it consistently in the header
                but we dont want it in the header until its loaded for the first time in the checkout form. Or anything we do has browser errors.
            */}
                {(typeof window !== 'undefined' && window.Worldpay) ? <script src="https://cdn.worldpay.com/v1/worldpay.js" /> : null}
            </Helmet>
            <SEO
                title={metaData.title ? `${metaData.title} || ${title}` : title}
                description={metaData.description || description}
                siteName={siteName}
                canonical={metaData.canonical}
                currentUrl={currentUrl}

                image={metaData.image || `${siteURL}/img/perfect-pergolas-splash-logo.png`}

                twitterHandle={twitterHandle}

                themeColor="#395501"

                manifest={undefined}
                icon16={undefined}
                icon32={undefined}
                icon180={undefined}
                icon192={undefined}
                icon270={undefined}

                articlePublisher={undefined}
                articleModifiedTime={undefined}
            />
            <Icons />
            <Navigation invertNav={invertNav} />
            <main className={mainClassName}>
                {children}
            </main>
            <Footer />
        </Fragment>
    );
};

Layout.defaultProps = {
    mainClassName: '',
};

export default Layout;
